import { getConsoleForNamespace } from './console';
var console = getConsoleForNamespace('timings');
var timings = {};
export var getPrev = function getPrev() {
  return typeof localStorage !== "undefined" && localStorage.getItem("litTimings") || "";
};
export var time = function time(ns, marker) {
  var now = Date.now();
  timings[ns] = !marker || !timings[ns] ? {
    start: now,
    marks: [],
    timeTo: {}
  } : timings[ns];
  timings[ns].marks.push({
    marker: marker,
    time: now
  });

  if (marker) {
    var _timings$ns, _timings$ns$marks, _timings$ns2;

    var id = now;
    var index = timings[ns].marks.length;
    var last = index - 2 >= 0 && ((_timings$ns = timings[ns]) === null || _timings$ns === void 0 ? void 0 : (_timings$ns$marks = _timings$ns.marks[index - 2]) === null || _timings$ns$marks === void 0 ? void 0 : _timings$ns$marks.time) || ((_timings$ns2 = timings[ns]) === null || _timings$ns2 === void 0 ? void 0 : _timings$ns2.start);
    var took = now - last;
    timings[ns].timeTo[marker] = took;
    console.log("[timings][".concat(ns, "] \"start\" to \"").concat(marker, "\" took ").concat(took, "ms"));
    var log = JSON.stringify({
      ns: ns,
      marker: marker,
      id: id,
      took: took,
      index: index
    }) + "\n";
    if (typeof localStorage !== "undefined") localStorage.setItem("litTimings", getPrev() + log);
  }
};
export var getTimings = function getTimings() {
  return timings;
};