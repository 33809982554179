import visit from 'unist-util-visit';
import before from 'unist-util-find-before';
import after from 'unist-util-find-after';
import yaml from 'js-yaml';
import { log, level } from '../utils/console';
import { notEqual } from 'assert';
import { getConsoleForNamespace } from '../utils/console';
var console = getConsoleForNamespace('frontmatter');
var FRONTMATTER_OPEN = '<!-- data';
var FRONTMATTER_CLOSE = '-->';
export default function () {
  return function (tree, file) {
    var matters = [];
    visit(tree, 'html', function (node, index, parent) {
      if (node.value.indexOf(FRONTMATTER_OPEN) === 0 && node.value.indexOf(FRONTMATTER_CLOSE) === node.value.length - FRONTMATTER_CLOSE.length) {
        console.log('Raw', node.value);
        var yamlString = node.value.slice(FRONTMATTER_OPEN.length, node.value.length - FRONTMATTER_CLOSE.length).trim();

        try {
          node.data = yaml.load(yamlString, 'utf8');
        } catch (err) {
          node.data = {
            error: err.toString()
          };
        }

        console.log('Parsed', yamlString);
        matters.push(node.data);
      }
    });
    file.data.frontmatter = matters.reduce(function (memo, matter) {
      return Object.assign({}, memo, matter || {});
    }, {});
  };
}