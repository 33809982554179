import heading from "mdast-util-heading-range";
import visit from "unist-util-visit";
import flatMap from "unist-util-flatmap";
import { getConsoleForNamespace } from '../utils/console';
import { Identity } from "../utils/functions";
var console = getConsoleForNamespace('sections', {
  disabled: true
});

var firstChild = function firstChild(node, type) {
  return node.children && node.children[0] && node.children[0].type === type;
};

var createCell = function createCell(node, nodes) {
  var pos = node.position; // pos.start.offset = pos.start.offset - pos.start.column
  // pos.start.column = 0

  return {
    type: "cell",
    position: pos,
    data: {
      hName: "cell",
      hProperties: {
        "class": "cell"
      }
    },
    children: nodes || [node]
  };
};

var createSection = function createSection(node, nodes) {
  if (!nodes) node.children = cellsFromNodes(node.children);
  return {
    type: "section",
    data: {
      hName: "section"
    },
    position: node.position,
    children: nodes || [node]
  };
};

var cellsFromNodes = function cellsFromNodes(nodes) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      addSectionDataToFirstCell = _ref.addSectionDataToFirstCell;

  var cells = [];
  var newCell = null;
  nodes.map(function (current, index) {
    var node = current;
    console.log("[Sections] child: ", index, node.type);

    if (node.type === "section") {
      newCell = null;
      cells.push(node);
    } else if (false && node.type === "list" && node.spread) {
      newCell = null;
      var listSection = createSection(node);
      cells.push(listSection);
    } else if (false && node.type === "listItem" && node.spread) {
      newCell = null;
      var listItem = node;

      if (firstChild(listItem, 'section')) {
        console.log("[Sections] ListItem with section: ", node.type);
        listItem.children = listItem.children.map(function (section) {
          section.children = cellsFromNodes(section.children);
        });
      } else {
        listItem.children = [createSection(node, node.children)];
      }

      cells.push(listItem);
    } else if (node.type === "code") {
      var next = nodes[index + 1];

      var attached = function attached(node) {
        return node && node.data && node.data.meta && node.data.meta.attached;
      };

      var nextIsAttached = attached(next);
      var singleCell = createCell(node);

      if (nextIsAttached) {
        newCell = singleCell;
      } else if (newCell && attached(node)) {
        newCell.children.push(node);
        if (node.position) newCell.position.end = node.position.end;
        cells.push(newCell);
        newCell = null;
      } else {
        newCell = null;
        cells.push(singleCell);
      }
    } else if (newCell) {
      newCell.children.push(node);
      if (node.position) newCell.position.end = node.position.end;
    } else {
      newCell = createCell(node);
      if (addSectionDataToFirstCell) newCell.data.section = addSectionDataToFirstCell;
      cells.push(newCell);
    }
  });
  return cells;
};

export var sections = function sections() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return function (tree) {
    console.log('[Sections II] Init.', args, tree.type, tree.children.length);
    var headings = 0;

    var newSection = function newSection(children) {
      var first = children[0];
      var last = children[children.length - 1];
      var depth = first.depth || 0;
      first.processed = true;
      return {
        type: 'section',
        data: {
          name: first.data.id,
          hName: 'section',
          hProperties: {
            depth: depth,
            id: first.data.id
          }
        },
        depth: depth,
        children: children,
        position: {
          start: first.position.start,
          end: last.position.end
        }
      };
    };

    visit(tree, 'heading', function (node, index, parent) {
      if (node.processed) {
        console.log("[Sections II] Ignoring already processed node ".concat(node.data.id));
      } else if (parent.type === 'root') {
        console.log("[Sections II] heading \"".concat(node.data.id, "\" ").concat(headings, ", depth: ").concat(node.depth)); // remove rehype ids 

        node.data.hProperties = {};
        var section = parent.children[index] = newSection([node]);
        var children = parent.children;

        for (var i = index + 1; i < children.length; i++) {
          if (!children[i] || children[i].processed) {
            console.log('Skipping removed', children[i]);
            break;
          }

          var nextNode = children[i];

          if ((nextNode.type === 'heading' || nextNode.type === 'section') && nextNode.depth <= node.depth) {
            console.log("[Sections II] ended \"".concat(node.data.id, "\" due to \"").concat(nextNode.data.id || nextNode.data.name, "\""), nextNode.type, nextNode.depth);
            console.log("[Sections II] contains: \"".concat(node.data.id, "\""), section.children.map(function (n) {
              return n.type;
            }).join(','));
            break;
          }

          console.log("[Sections II] child index: ".concat(i, ", type: ").concat(nextNode.type, " depth: ").concat(nextNode.depth, " id: ").concat(nextNode.data && (nextNode.data.id || nextNode.data.name)));
          section.children.push(nextNode);
          if (nextNode.position) section.position.end = nextNode.position.end;
          delete parent.children[i];
        }

        headings++;
        node = section;
        node.children = cellsFromNodes(node.children, {
          addSectionDataToFirstCell: {
            id: section.data.id,
            position: section.position
          }
        });
      } else {
        console.log('[Sections II] WARN: Header parent not root', node.data.id);
      }
    }, true);
    console.log("Headings: ", headings);

    if (!headings) {
      tree.children = cellsFromNodes(tree.children);
    } else {
      tree.children = tree.children.filter(Identity);
    }
  };
};

var cells = function cells() {
  return function (tree) {
    visit(tree, cells);
  };
};

export var ungroupSections = function ungroupSections() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function () {
    return function (tree) {
      console.log("[UnSection] Init", options);
      tree = flatMap(tree, function (node) {
        if (node.type === "cell") {
          return node.children;
        } else if (node.type === "section") {
          return node.children;
        } else {
          return [node];
        }
      });
      return tree;
    };
  };
};