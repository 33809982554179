import filter from 'unist-util-filter';
import { selectAll as select } from 'unist-util-select';
import { getConsoleForNamespace } from './console';
var console = getConsoleForNamespace('util');
export var atPos = function atPos(pos) {
  return function (node) {
    var pos2 = node.position;

    if (!pos2 || !pos2.start || !pos2.end) {
      console.error("no pos", node);
      return false;
    }

    var startInside = pos2.start.line >= pos.start.line && pos2.start.line <= pos.end.line;
    var endInside = pos2.end.line >= pos.start.line && pos2.end.line <= pos.end.line;
    var wraps = pos2.start.line <= pos.start.line && pos2.end.line >= pos.end.line;
    var any = wraps || startInside || endInside; // console.log("atPos: " + node.type, any ,pos2.start.line, pos2.end.line, wraps, startInside, endInside, pos.start.line, pos.end.line)

    return any;
  };
};
export var selectAll = function selectAll(type, pos, tree) {
  var filteredTree = filter(tree, atPos(pos));
  var nodes = select(type, filteredTree);
  return nodes;
};