import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { join, resolve, relative as isRelative, dirname } from "path";
import visit from "unist-util-visit";
import { getConsoleForNamespace } from "../utils/console";
var console = getConsoleForNamespace("links");
export var resolveLinks = function resolveLinks() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    litroot: "",
    filepath: "",
    files: []
  };
  return function () {
    return function (tree, file) {
      var _file$data;

      // console.log("[Links] Init", file.path, options);
      options.filepath = options.filepath || (file === null || file === void 0 ? void 0 : (_file$data = file.data) === null || _file$data === void 0 ? void 0 : _file$data.canonical) || file.path;
      return visit(tree, isLink, transform(options));
    };
  };
};
export var slug = function slug(str) {
  return str.replace(/[^\w\s/-]+/g, "").trim().replace(/\s+/g, "_").toLowerCase();
};
export var resolver = function resolver(str) {
  // console.log("Input: ", str);
  if (!str) {
    throw Error("No string to resolve");
    process.exit(1);
  }

  var main, title, doc, hash, base, query, file, ext, _;

  try {
    var _str$split = str.split("|");

    var _str$split2 = _slicedToArray(_str$split, 2);

    main = _str$split2[0];
    title = _str$split2[1];

    var _main$split = main.split("#");

    var _main$split2 = _slicedToArray(_main$split, 2);

    doc = _main$split2[0];
    hash = _main$split2[1];

    var _doc$split = doc.split("?");

    var _doc$split2 = _slicedToArray(_doc$split, 2);

    base = _doc$split2[0];
    query = _doc$split2[1];

    var _ref = base.match(/([^\.]+)(\.[a-zA-Z0-9]+)?$/) || [];

    var _ref2 = _slicedToArray(_ref, 3);

    _ = _ref2[0];
    file = _ref2[1];
    var _ref2$ = _ref2[2];
    ext = _ref2$ === void 0 ? '' : _ref2$;
  } catch (err) {
    console.log({
      str: str,
      main: main,
      title: title,
      doc: doc,
      hash: hash,
      base: base,
      query: query,
      file: file,
      ext: ext,
      _: _
    });
    console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<', err);
    process.exit(1);
  }

  var root = file && file[0] && ['/', '.'].indexOf(file[0]) >= 0 ? '' : '/';
  var path = file && root + slug(file);
  var section = hash && slug(hash);
  var href = path + (query ? "?" + query : "") + (hash ? "#" + section : ""); // console.log('Href: ', href)

  return {
    title: title,
    path: path,
    ext: ext,
    hash: hash,
    query: query,
    section: section,
    href: href
  };
};

var isLink = function isLink(node) {
  return ["link", "wikiLink"].indexOf(node.type) >= 0;
};

var transform = function transform(options) {
  return function (node, index, parent) {
    return decorateLinkNode(node, options.litroot, options.filepath, options.files);
  };
};

export var wikiLinkOptions = function wikiLinkOptions() {
  var files = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return {
    permalinks: files,
    pageResolver: function pageResolver(name) {
      // console.log('Resolving', name)
      var _resolver = resolver(name),
          path = _resolver.path,
          ext = _resolver.ext;

      var exts = ['.lit', '/index.lit', '.md', ext];
      var opts = exts.map(function (ext) {
        return "".concat(path).concat(ext);
      }); // console.log(opts)

      return opts; //.filter(file => files.indexOf(file) >= 0)[0] || opts
    }
  };
}; // ({
//     permalinks: files,
//     pageResolver: nameToPermalinks,
//     hrefTemplate: (permalink) => `${permalink}?file=${permalink}`
// })

var linkToUrl = function linkToUrl(link, root) {
  if (link.type === "wikiLink") {
    // console.log(link)
    return link.data.permalink;
  } else {
    return link.url;
  }
};

export var decorateLinkNode = function decorateLinkNode(link) {
  var root = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var filepath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  var files = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  // console.log(link)
  var wikilink = link.type === "wikiLink";
  var url = linkToUrl(link, root);
  var external = /^(https?\:)?\/\//.test(url);
  var absolute = !external && /^\//.test(url);
  var fragment = /^(\?|#)/.test(url);
  var relative = url && !absolute && !fragment && !external;

  var srcToGH = function srcToGH(src, prefix) {
    return join(prefix, src);
  };

  var relToCanonical = function relToCanonical(src, link) {
    return resolve(dirname(src), link);
  };

  var canonicalToRel = function canonicalToRel(src1, src2) {
    return isRelative(dirname(src1), src2);
  };

  var canonical = url;
  var href = url;

  var _url$split = url.split(/(\?|#)/),
      _url$split2 = _slicedToArray(_url$split, 2),
      base = _url$split2[0],
      frag = _url$split2[1];

  if (relative) {
    canonical = relToCanonical(filepath, base);
    href = url.replace(/\.(md|lit)/i, ".html");
  } else if (absolute) {
    var rel = canonicalToRel(filepath, url);
    href = rel.replace(/\.(md|lit)/i, ".html");
  }

  link.type = "link";
  link.url = href;
  var tempTitle = link.title || link.value;

  if (tempTitle) {
    var valueAndTitle = tempTitle.split("|");
    link.title = valueAndTitle[0];
    link.value = valueAndTitle[1] || valueAndTitle[0];
  }

  var exists = files.indexOf(canonical) >= 0;
  var data = {
    external: external,
    absolute: absolute,
    fragment: fragment,
    relative: relative,
    canonical: canonical,
    wikilink: wikilink,
    exists: exists
  };
  link.data = Object.assign({}, data, {});

  if (wikilink) {
    link.children = [{
      position: link.position,
      type: "text",
      value: link.value
    }];
  }

  link.data.hProperties = {
    wikilink: wikilink,
    filepath: filepath,
    root: root,
    data: data
  };
  delete link.value;
  console.log("[".concat(filepath, "] resolving (").concat(link.type, ") [").concat(canonical, "] exists: ").concat(exists), link.url, link);
  return link;
};
export default {
  resolveLinks: resolveLinks,
  wikiLinkOptions: wikiLinkOptions,
  resolver: resolver,
  linkToUrl: linkToUrl,
  decorateLinkNode: decorateLinkNode
};