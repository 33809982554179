export var viewer = function viewer(_ref) {
  var children = _ref.children,
      node = _ref.node,
      React = _ref.React;
  var ch = children;
  var chc = ch[0].props.children;
  var rc = React.createElement;
  var np = node.properties;
  var meta = np && np.meta;
  var dirs = meta && meta.directives;
  var tags = meta && meta.tags;
  var dc = dirs && dirs.map(function (d) {
    return 'dir-' + d;
  });
  var tc = tags && tags.map(function (t) {
    return 'tag-' + t;
  });
  var classes = ['md-block'];

  var add = function add(arr) {
    return arr.forEach(function (c) {
      classes.push(c);
    });
  };

  if (dc) add(dc);
  if (tc) add(tc);
  var className = classes.filter(function (x) {
    return x;
  }).join(' ').trim();
  return rc('div', {
    className: className
  }, chc);
};