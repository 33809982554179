// This files source is defined in [[testing/section_grouping|🔬 Testing Section grouping]]
export var sections = function sections(options) {
  return function () {
    return function (tree) {
      try {
        var processSection = options.processSection;
        var stack = [tree];
        var nodes = tree.children;
        tree.children = [];

        var createSection = function createSection(node) {
          var _node$data, _node$data2;

          if (!node.position) throw new Error("Node ".concat(node.type, " has no position")); // if (typeof node.depth === 'undefined') throw new Error(`Node ${node.type} has no depth`)
          // if (!node.data?.id) throw new Error(`Node ${node.type} has no data.id`)

          var section = {
            type: 'section',
            children: [node],
            depth: node.depth || 0,
            position: node.position,
            data: {
              name: (_node$data = node.data) === null || _node$data === void 0 ? void 0 : _node$data.id,
              hName: 'section',
              hProperties: {
                depth: node.depth || 0,
                id: (_node$data2 = node.data) === null || _node$data2 === void 0 ? void 0 : _node$data2.id
              }
            }
          };
          stack[stack.length - 1].children.push(section);
          stack.push(section);
        };

        var shouldPopStack = function shouldPopStack(n, force) {
          var s = stack[stack.length - 1];
          var notRoot = s.type !== 'root';
          var nShallow = s.depth >= n.depth;
          return notRoot && (force || nShallow);
        };

        var endSection = function endSection() {
          var s = stack.pop();

          if (processSection) {
            try {
              processSection(s);
            } catch (err) {
              throw new Error("Failed to processSection due to ".concat(err.message));
            }
          }
        };

        nodes.map(function (node, index) {
          console.log("sections", index, node);
          var section = stack[stack.length - 1];

          if (section.type === 'root') {
            createSection(node);
          } else if (node.type === 'heading') {
            if (node.depth > section.depth) {
              createSection(node);
            } else {
              while (shouldPopStack(node)) {
                endSection();
              }

              createSection(node);
            }
          } else {
            // some list nodes have no position (toc?)
            if (!section.position) {
              throw new Error("Section ".concat(section.type, " has no position"));
            }

            section.children.push(node);
            if (node.position) section.position.end = node.position.end;
          }

          if (!nodes[index + 1]) {
            while (shouldPopStack(node, true)) {
              endSection();
            }
          }
        });
      } catch (err) {
        throw new Error("Failed to group sections due to ".concat(err.message));
      }
    };
  };
};