export var MsToRelative = function MsToRelative(ms, p, f) {
  // console.log('MsToRelative', ms, p, f)
  var abs = Math.abs(ms);
  var nowish = abs <= 1000;
  if (nowish) return 'now';
  var second = 1000;
  var seconds = abs / second;
  var future = ms > 0;
  var minute = second * 60;
  var minutes = abs / minute;
  var hour = minute * 60;
  var hours = abs / hour;
  var day = hour * 24;
  var days = abs / day;
  var week = day * 7;
  var weeks = abs / week;
  var suffix = future ? f || 'from now' : p || 'ago';
  var val, unit; // console.log({ms, abs, seconds, minutes, hours, days, weeks})

  if (abs < minute) {
    val = Math.round(seconds);
    unit = 's';
  } else if (abs < hour) {
    val = Math.round(minutes);
    unit = 'm';
  } else if (abs < day) {
    val = hours % 1 > 0.2 ? hours.toFixed(1) : Math.round(hours);
    unit = 'h';
  } else if (abs < week) {
    val = days % 1 > 0.2 ? days.toFixed(1) : Math.round(days);
    unit = 'd';
  } else {
    val = weeks % 1 > 0.2 ? weeks.toFixed(1) : Math.round(weeks);
    unit = 'w';
  } // if (val > 1) unit = `${unit}s`


  return "".concat(val).concat(unit, " ").concat(suffix);
};
export var DatesToRelativeDelta = function DatesToRelativeDelta(a, b) {
  // console.log('DatesToRelativeDelta', a, b)
  var A = new Date(a);
  var B = new Date(b);
  var deltaMs = A.getTime() - B.getTime();
  return MsToRelative(deltaMs, 'older', 'newer');
};