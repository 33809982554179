import _typeof from "@babel/runtime/helpers/typeof";
// extracted from mdast-util-to-string
export var to_string = function to_string(node, options) {
  var _ref = options || {},
      _ref$includeImageAlt = _ref.includeImageAlt,
      includeImageAlt = _ref$includeImageAlt === void 0 ? true : _ref$includeImageAlt;

  return one(node, includeImageAlt);
};

function one(node, includeImageAlt) {
  return node && _typeof(node) === 'object' && (node.value || (includeImageAlt ? node.alt : '') || 'children' in node && all(node.children, includeImageAlt) || Array.isArray(node) && all(node, includeImageAlt)) || '';
}

function all(values, includeImageAlt) {
  var result = [];
  var index = -1;

  while (++index < values.length) {
    result[index] = one(values[index], includeImageAlt);
  }

  return result.join('');
}