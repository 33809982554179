import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import visit from 'unist-util-visit';
import { getConsoleForNamespace } from '../utils/console';
var console = getConsoleForNamespace('codeblocks');
var LSP = '__.litsp__';
var NONESCAPEDSPACES_REGEX = /([^\\])\s/g;
var LANG = 'lang';
var ATTR = 'attribute';
var TAG = 'tag';
var DIREC = 'directive';
var FILENAME = 'filename';
var URI = 'uri';
var UNKNOWN = 'unknown';

var isListType = function isListType(t) {
  return [TAG, DIREC, UNKNOWN].indexOf(t) >= 0;
};

export default function () {
  return function (tree) {
    return visit(tree, 'code', transform);
  };
}

function transform(node, index, parent) {
  console.log('[CodeBlocks] Visiting: ', node.lang, node.meta);
  var litMeta = parseMeta(node);
  node.data = _objectSpread(_objectSpread({}, node.data), {}, {
    meta: litMeta,
    hProperties: {
      className: litMeta && litMeta.tags ? litMeta.tags.map(function (t) {
        return "tag-".concat(t);
      }).join(' ') : '',
      meta: litMeta
    }
  });
  return node;
}

export var parseMeta = function parseMeta(node) {
  var raw = "".concat(node.lang || '', " ").concat(node.meta || '').trim();
  console.log("[CodeBlocks] lang: \"".concat(node.lang, "\" meta: \"").concat(node.meta, "\", raw: \"").concat(raw, "\""));
  var isOutput = raw.indexOf('>') === 0;
  var hasOutput = node.meta && node.meta.indexOf('>') >= 0;
  var hasSource = node.meta && node.meta.indexOf('<') >= 0;
  var input = raw;

  var _, output, source;

  var fromSource;

  if (isOutput) {
    var _raw$split$map = raw.split('>').map(function (x) {
      return x.trim();
    });

    var _raw$split$map2 = _slicedToArray(_raw$split$map, 2);

    _ = _raw$split$map2[0];
    input = _raw$split$map2[1];
  }

  if (hasOutput) {
    var _input$split$map = input.split('>').map(function (x) {
      return x.trim();
    });

    var _input$split$map2 = _slicedToArray(_input$split$map, 2);

    input = _input$split$map2[0];
    output = _input$split$map2[1];
  }

  if (hasSource) {
    var _input$split$map3 = input.split('<').map(function (x) {
      return x.trim();
    });

    var _input$split$map4 = _slicedToArray(_input$split$map3, 2);

    input = _input$split$map4[0];
    source = _input$split$map4[1];
  }

  var meta = input.replace(NONESCAPEDSPACES_REGEX, "$1" + LSP).split(LSP).map(ident).reduce(reduceParts, {});
  meta.isOutput = isOutput;
  meta.output = output && parseMeta({
    meta: output
  });
  meta.hasOutput = !!output;
  meta.hasSource = !!source;
  meta.source = source && parseMeta({
    lang: 'txt',
    meta: source
  });
  meta.raw = raw;
  if (meta.source) meta.fromSource = meta.source.filename || meta.source.uri;
  return meta;
};

function isUri(str) {
  return str.startsWith('http') || str.startsWith('//');
}

function ident(x, i) {
  var type,
      value = x;

  if (i === 0) {
    type = LANG;
  } else if (x && x[0]) {
    if (x[0] === "#") {
      type = TAG;
      value = x.slice(1);
    } else if (x[0] === "!") {
      type = DIREC;
      value = x.slice(1);
    } else if (x.indexOf("=") > 0) {
      type = ATTR;
      value = x.split("=");
      value = {
        type: value[0],
        value: value[1]
      };
    } else if (i === 1) {
      if (isUri(x)) type = URI;else type = FILENAME;
    } else if (!type) type = UNKNOWN;
  }

  return {
    type: type,
    value: value
  };
}

function reduceParts(memo, item, i) {
  memo.attrs = memo.attrs || {};

  if (item.type === ATTR) {
    memo.attrs[item.value.type] = item.value.value;
    item = item.value;
  }

  if (isListType(item.type)) {
    var collective = "".concat(item.type, "s");

    if (memo[collective]) {
      memo[collective].push(item.value);
    } else {
      memo[collective] = [item.value];
    }

    if (item.type === DIREC) {// memo.attrs[item.value] = true
    }
  } else {
    memo[item.type] = item.value;
  }

  return memo;
}

export var metaToString = function metaToString(meta) {
  var prefix = function prefix(p) {
    return function (str) {
      return p + str;
    };
  };

  var tag = prefix("#");
  var dir = prefix("!");

  var attr = function attr(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return "".concat(key, "=").concat(value);
  };

  var parts = [];
  var dirs = meta.directives || [];
  var tags = meta.tags || [];
  var attrs = meta.attrs || {};
  parts.push(meta.isOutput && ">");
  parts.push(meta.lang);
  parts.push(meta.filename || meta.uri);
  dirs.forEach(function (d) {
    return parts.push(dir(d));
  });
  Object.entries(attrs).forEach(function (e) {
    return parts.push(attr(e));
  });
  tags.forEach(function (t) {
    return parts.push(tag(t));
  });

  if (meta.source) {
    parts.push("<");
    parts.push(metaToString(meta.source));
  }

  if (meta.output) {
    parts.push(">");
    parts.push(metaToString(meta.output));
  }

  return parts.filter(function (x) {
    return x;
  }).join(" ");
};