export var cells = function cells(section) {
  var stack = [];
  var nodes = section.children;
  section.children = stack;

  var needNewCell = function needNewCell() {
    var _cell$data, _cell$children, _cell$children$;

    var cell = stack[stack.length - 1];
    return !cell || ((_cell$data = cell.data) === null || _cell$data === void 0 ? void 0 : _cell$data.code) || cell.type === 'section' || ((_cell$children = cell.children) === null || _cell$children === void 0 ? void 0 : (_cell$children$ = _cell$children[0]) === null || _cell$children$ === void 0 ? void 0 : _cell$children$.type) === 'heading';
  };

  var createCell = function createCell(node) {
    stack.push({
      type: 'cell',
      children: [node],
      position: node.position,
      data: {
        code: node.type === 'code',
        hName: "cell",
        hProperties: {
          "class": "cell"
        }
      }
    });
  };

  var addToCell = function addToCell(node) {
    var cell = stack[stack.length - 1];
    cell.children.push(node);
    if (node.position) cell.position.end = node.position.end;
  };

  nodes.map(function (node, index) {
    var prev = index ? stack[index - 1] : null;
    if (node.type === 'section') stack.push(node);else if (node.type === 'code') {
      var _prev$data, _node$data, _node$data$meta;

      if (prev !== null && prev !== void 0 && (_prev$data = prev.data) !== null && _prev$data !== void 0 && _prev$data.code && (_node$data = node.data) !== null && _node$data !== void 0 && (_node$data$meta = _node$data.meta) !== null && _node$data$meta !== void 0 && _node$data$meta.attached) {
        addToCell(node);
      } else {
        createCell(node);
      }
    } else if (needNewCell()) {
      createCell(node);
    } else {
      addToCell(node);
    }
  });
  return section;
};