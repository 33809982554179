import { NoOp } from './functions';
var ROOT_NS = '.lit';
var ROOT_PREFIX = "".concat(ROOT_NS);

var debugKeys = function debugKeys() {
  var debugStr = '';

  if (typeof process !== 'undefined' && process.env && process.env.DEBUG) {
    debugStr = process.env.DEBUG;
  }

  if (typeof window !== 'undefined' && window.location) {
    var _debugKeys = localStorage.getItem('litDebug') || '';

    if (_debugKeys) debugStr = _debugKeys;
  }

  return debugStr.split(',');
}; // console.log("[console] log mask:", debugKeys().join(',') )


var shouldLog = function shouldLog(ns) {
  var keys = debugKeys();
  if (keys.indexOf("-".concat(ns)) >= 0) return false;
  return keys.indexOf('*') >= 0 || keys.indexOf('All') >= 0 || keys.indexOf(ns) >= 0;
};

var level = function level(_level, fn) {
  var lvlIndent = Array(_level).fill('  ').join('');
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (_level <= debug_level() || shouldLog(_level)) fn.apply(void 0, ["[lit]{".concat(_level, "}").concat(lvlIndent)].concat(args));
  };
};

var prefixArgs = function prefixArgs(prefix, fn, self) {
  return function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    var newArgs = [prefix].concat(args);
    fn.apply(self, newArgs);
  };
};

var getConsole = function getConsole(ns) {
  var prefix = "[".concat(ROOT_PREFIX, ":").concat(ns, "] ");
  return {
    level: level,
    log: prefixArgs(prefix, console.log, console),
    dir: prefixArgs(prefix, console.dir, console),
    info: prefixArgs(prefix, console.info, console),
    error: prefixArgs(prefix, console.error, console),
    time: console.time,
    timeEnd: console.timeEnd,
    getConsoleForNamespace: getConsoleForNamespace
  };
};

export var Console = getConsole(ROOT_NS);
export function getConsoleForNamespace(ns) {
  if (shouldLog(ns)) {
    return getConsole(ns);
  } else {
    if (debugKeys()[0] !== 'None') console.log("[".concat(ROOT_PREFIX, "] Hiding console for NS \"").concat(ns, "\""));
    var prefix = "[".concat(ROOT_PREFIX, ":").concat(ns, "] ");
    return {
      level: NoOp,
      log: NoOp,
      dir: NoOp,
      info: NoOp,
      error: prefixArgs(prefix, console.error, console),
      time: NoOp,
      timeEnd: NoOp
    };
  }
}